import React from "react";
import { styled } from "goober";
import { t } from "i18next";

import { setDebugMode } from "@/feature-debug/actions.mjs";
import getOSType from "@/util/get-os-type.mjs";

export const DebugBorder = () => {
  return (
    <>
      <Banner className="top" />
      <Banner className="right" />
      <Banner className="bottom" />
      <Banner className="left" />
      <ExitDebugButton onClick={() => setDebugMode(false)}>
        {t("common.exit-debug", "Exit Debug Mode")}
      </ExitDebugButton>
    </>
  );
};

const ExitDebugButton = styled("button")`
  position: fixed;
  opacity: 0.5;
  top: 0;
  ${getOSType() === "win32" ? "left: 0;" : "right: 0;"}
  z-index: 9999999999;
  background: var(--subscriber);
  color: var(--shade0);
  font-weight: bold;
  padding: var(--sp-0_5);
  line-height: 1;
  font-size: var(--sp-3);
  text-shadow:
    1px 1px 1px var(--shade10),
    -1px -1px 1px var(--shade10);
  border: none;
  border-bottom-left-radius: var(--sp-0_5);
  border-bottom-right-radius: var(--sp-0_5);
  cursor: pointer;
  transition: opacity 0.2s ease-in-out 1s;
  background: repeating-linear-gradient(
    45deg,
    var(--subscriber),
    var(--subscriber) 8px,
    var(--shade10) 8px,
    var(--shade10) 16px
  );

  &:hover {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
  }
`;

const Banner = styled("div")`
  position: fixed;
  opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: all 0.2s ease-in-out 1s;
  background: repeating-linear-gradient(
    45deg,
    var(--subscriber),
    var(--subscriber) 8px,
    var(--shade10) 8px,
    var(--shade10) 16px
  );
  z-index: 9999999999;
  color: var(--shade0);
  font-weight: bold;
  font-size: var(--sp-2);
  text-shadow:
    1px 1px 1px var(--shade10),
    -1px -1px 1px var(--shade10);
  height: var(--sp-0_5);
  width: var(--sp-0_5);

  &.top {
    width: 100%;
    top: 0;
    left: 0;
  }
  &.right {
    height: 100%;
    top: 0;
    right: 0;
  }
  &.bottom {
    width: 100%;
    bottom: 0;
    left: 0;
  }
  &.left {
    height: 100%;
    top: 0;
    left: 0;
  }
`;
