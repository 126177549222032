import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  writePromise,
} from "@/__main__/app-state.mjs";
import { IS_APP, isPersistent } from "@/__main__/constants.mjs";
import { readData } from "@/__main__/get-data.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import globals from "@/util/global-whitelist.mjs";

const DEBUGGING_TIME = 2 * 60 * 60 * 1000; // 2 hours / 2 games worth?

export async function setDebugMode(boolean: boolean) {
  const expiration = boolean
    ? new Date(Date.now() + DEBUGGING_TIME).toISOString()
    : "";

  if (IS_APP) {
    await blitzMessage(EVENTS.DB_WRITE, {
      key: "debugMode",
      value: expiration,
    });
    await blitzMessage(EVENTS.APP_RESTART);
  } else {
    const debug = { expiration };
    debug[isPersistent] = Date.now() + DEBUGGING_TIME;

    writeState.debug = debug;

    await writePromise();
    globals.location.reload();
  }
}

export async function getDebugMode() {
  let expiration = "";

  if (IS_APP) {
    expiration = (await blitzMessage(EVENTS.DB_READ, "debugMode")) as string;
  } else {
    const debug = await readData(["debug"]);
    expiration = debug?.expiration;
  }
  return expiration ? new Date() < new Date(expiration) : false;
}
