import React, { useEffect, useState } from "react";
import { t } from "i18next";

import { getDebugMode, setDebugMode } from "@/feature-debug/actions.mjs";
import {
  SettingsContent,
  SettingsDescription,
  SettingsLabel,
  SettingsListItem,
} from "@/hub-settings/Common.style.jsx";
import { ToggleSwitch } from "@/hub-settings/ToggleSwitch.jsx";

export function DebugSettingsListItem() {
  const [debug, setDebug] = useState(false);
  useEffect(() => {
    getDebugMode().then(setDebug);
  }, []);
  return (
    <SettingsListItem key="debugger">
      <SettingsContent>
        <SettingsLabel className="type-form--button">
          {t("common:settings.debug", "Debug Mode")}
        </SettingsLabel>
        <SettingsDescription className="type-caption">
          {t(
            "common:settings.debugDescription",
            "Allows our developers to see what's going on in the app.\n\nThis is useful for debugging issues, but you should only enable it when asked to do so by a developer.\n\nIf you're not sure what this is, you should leave it off as it will introduce latency into your games.",
          )}
        </SettingsDescription>
      </SettingsContent>
      <ToggleSwitch
        className=""
        name="debug"
        disabled={false} // This will always be false, because if feature-debug is disabled, this component won't be rendered.
        value={debug}
        onChange={() => setDebugMode(!debug)}
      />
    </SettingsListItem>
  );
}
