import mainRefs from "@/__main__/refs.mjs";
import { setFeatureFlag } from "@/app/actions.mjs";
import { getDebugMode } from "@/feature-debug/actions.mjs";
import { DebugBorder } from "@/feature-debug/DebugBorder.style.jsx";
import { DebugSettingsListItem } from "@/feature-debug/DebugSettingsListItem.jsx";
import settingsRefs from "@/hub-settings/refs.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const originals = mapOriginalRefs({
  mainRefComponents: mainRefs.components,
  settingsRefs,
});

export async function setup() {
  const isDebugMode = await getDebugMode();

  originals.append({
    settingsRefs: {
      settingsListItems: [DebugSettingsListItem],
    },
  });

  if (isDebugMode) {
    originals.append({
      mainRefComponents: {
        floatingElementsTop: [DebugBorder],
      },
    });

    setFeatureFlag("datadog", true);
  }
}

export function teardown() {
  originals.restore();

  setFeatureFlag("datadog", false);
}
